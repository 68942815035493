<template>
	<div class="section has-background-white">
		<div class="columns">
			<div class="column container is-two-thirds box">
        <div class="notification is-warning is-light has-text-weight-light" style="border:0.1em solid lightgrey">
          Ceci est un espace sécurisé d'envoi de vos documents.
          <br>Pour en garantir la fiabilité, à chaque envoi, un nouveau formulaire sera à remplir.
          <br><b>Attention, il est possible de ne pas pouvoir envoyer des documents si votre ordinateur est protégé par un pare-feu, veuillez le désactiver dans ce cas.</b>
        </div>
				<div class="field is-horizontal">
					<div class="field-body">
						<div class="field">
							<p class="control has-icons-left">
								<input v-model="data.lastname" class="input" type="text" placeholder="Nom" id="lastname">
								<span class="icon is-large is-left">
									<i class="fas fa-user"></i>
								</span>
							</p>
						</div>
						<div class="field">
							<p class="control has-icons-left">
								<input v-model="data.firstname" class="input" type="text" placeholder="Prénom"
									id="firstname">
								<span class="icon is-left">
									<i class="fas fa-user"></i>
								</span>
							</p>
						</div>
					</div>
				</div>
				<div class="field">
					<div class="control has-icons-left">
						<input v-model="data.email" class="input" type="email" placeholder="Email" id="email">
						<span class="icon is-left">
							<i class="fas fa-envelope"></i>
						</span>
					</div>
				</div>
				<div class="field is-horizontal">
					<div class="field-body">
						<div class="field">
							<div class="field has-addons">
								<p class="control">
									<a class="button is-static">+33</a>
								</p>
								<p class="control is-expanded has-icons-right">
									<input v-model="data.phone" class="input" type="tel" placeholder="Téléphone" id="phone">
									<span class="icon is-right">
										<i class="fas fa-phone"></i>
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="field">
					<div class="control has-icons-right">
						<textarea v-model="comment" class="textarea" placeholder="Commentaire" id="comment"></textarea>
						<span class="icon is-right">
							<i class="fas fa-comment"></i>
						</span>
					</div>
				</div>
				<div class="tags are-medium mt-5">
					<span class="tag is-normal info">
						Limite d'envoi : 50 Mo/fichier
					</span>
					<span class="tag is-normal info">
						Vous pouvez envoyer jusqu'à 30 fichiers
					</span>
				</div>
				<div v-if="alert" class="notification is-warning is-light">
					<strong>{{ alert }}</strong>
				</div>
				<div class="field">
					<div class="control">
						<form id="dropzone" class="dropzone" style="border-radius:2rem;background:#fffaf0">
							<div class="dz-message" data-dz-message><span>Déposez vos fichiers ici</span></div>
						</form>
					</div>
				</div>
				<hr>
				<div id="recaptchaField" style="display: inline-block;"></div>
				<div class="mt-2 control has-text-centered">
					<button @click="validation()" class="button is-large is-warning">Envoyer</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FormService from '@/services/FormService'
import Dropzone from 'dropzone'

let data = {
	lastname: null,
	firstname: null,
	email: null,
	phone: null,
}
let myDropzone;
let nbLink = 0;
let uploadProcessing = false;
const links = {}
let timer = 0;

export default {
	name: 'FormComp',
	data() {
		return {
			data,
			comment: '',
			alert: ''
		}
	},
	mounted() {
		myDropzone = new Dropzone('#dropzone', {
			url: process.env.BASE_URL + "api/upload",
			method: "post",
			uploadMultiple: true,
			maxFiles: 30,
			addRemoveLinks: true,
			dictRemoveFile: "Supprimer",
			dictCancelUpload : "",
			maxFilesize: 50,
			autoProcessQueue: false,
			parallelUploads: 30
		});
		myDropzone.on("addedfile", async (file) => {
			if (file.fullPath !== undefined) {
				this.alert = 'Veuillez déposer des fichiers uniquement';
				myDropzone.removeFile(file);
				return ;
			}
			if (uploadProcessing === true || myDropzone.getQueuedFiles().length) {
				setTimeout(() => {
					this.doUpload(file);
				}, timer += 1000);
			}
			else
				this.doUpload(file);
		});
		myDropzone.on("removedfile", (file) => {
			uploadProcessing = true;
			for (let i in links) {
				if (i == file.upload.filename) {
					delete links[i];
					--nbLink;
					break;
				}
			}
			uploadProcessing = false;
		});
		window.onload = () => {
			this.initReCaptcha();
		};
	},
	methods: {
    async doUpload(file) {
      uploadProcessing = true;
        if (file.size < 50000000) {
          if (nbLink <= 30) {
            let reader = new FileReader();

            reader.onload = async function () {
              const value = reader.result.toString();

              let data = {
                filename: file.name,
                content: value,
                size: file.size,
                type: file.type
              };

              const response = await FormService.upload(data);

              if (!response || (response && (response.status == 400 || response.status == 500 || !response.data)) || (response && response.data && response.data.length < 2)) {
                myDropzone.removeFile(file);
                alert("Une erreur est survenue lors du chargement du fichier " + file.name + ", veuillez réessayer");
              } else {
                links[file.upload.filename] = {link: response.data[0], expires: response.data[1]};
                ++nbLink;
                if (response) {
                  myDropzone.processQueue();
                }
              }
            };

            reader.readAsDataURL(file);
        }
        else {
          myDropzone.removeFile(file);
          alert("Le fichier " + file.name + " dépasse la limite de 30 fichiers");
        }
      }
      else {
        myDropzone.removeFile(file);
        alert("Le fichier " + file.name + " est trop volumineux");
      }
      uploadProcessing = false;
    },
		initReCaptcha() {
			if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render === 'undefined') {
				this.initReCaptcha();
			}
			else {
				grecaptcha.render('recaptchaField', {
					sitekey: process.env.VUE_APP_GOOGLE_TOKEN,
				});
			}
		},
		checkInput() {
			let errors = [];
			let status = true;

			for (let i in data) {
				if (i !== "links") {
					if (document.getElementById(i)) {
						document.getElementById(i).classList.remove("is-danger");
						document.getElementById(i).classList.remove("is-success");
					}
				}
			}

      if (!/^[-\w\s]+$/.test(data.lastname)) {
				document.getElementById("lastname").classList.add("is-danger");
				errors.push("Le nom ne doit contenir que des lettres.");
				status = false;
			} else {
				document.getElementById("lastname").classList.add("is-success");
			}

      if (!/^[-\w\s]+$/.test(data.firstname)) {
				document.getElementById("firstname").classList.add("is-danger");
				errors.push("Le prénom ne doit contenir que des lettres.");
				status = false;
			} else {
				document.getElementById("firstname").classList.add("is-success");
			}

			if (!/^[0-9]+$/.test(data.phone)) {
				document.getElementById("phone").classList.add("is-danger");
				errors.push("Le numéro de téléphone ne doit contenir que des chiffres.");
				status = false;
			} else {
				document.getElementById("phone").classList.add("is-success");
			}

			if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,6}$/.test(data.email)) {
				document.getElementById("email").classList.add("is-danger");
				errors.push("L'adresse email n'est pas valide.");
				status = false;
			} else {
				document.getElementById("email").classList.add("is-success");
			}

			if (errors.length > 0) {
				alert(errors.join("\n"));
			}
			return status;
		},
		validation() {
			if (uploadProcessing == true || myDropzone.getQueuedFiles().length) {
				alert("Un fichier est en cours d'upload/suppression, veuillez patienter");
				return ;
			}
			let emptyField = false;
			for (let i in data) {
				if (i !== "links") {
					if (document.getElementById(i) && (data[i] === null || !data[i])) {
						document.getElementById(i).classList.remove("is-success");
						document.getElementById(i).classList.add("is-danger");
						emptyField = true;
					}
					else {
						document.getElementById(i).classList.remove("is-danger");
						document.getElementById(i).classList.add("is-success");
					}
				}
			}
			if (emptyField === true) {
				alert("Veuillez remplir tous les champs du formulaire");
				return;
			}
			else if (!nbLink)
				alert("Vous n'avez pas envoyé de fichier");
			else if (data.lastname && data.firstname && data.email && data.phone && nbLink && this.checkInput()) {
				let dataCaptcha = grecaptcha.getResponse();
				FormService.captcha({ token: dataCaptcha }).then(response => {
					if (response.data === 200 || response.data === 500) {
						if (this.comment)
							Object.assign(data, { comment: this.comment });
						data.links = links;
						FormService.process(data).then(response => {
							if (response.data == '200')
								location.replace(process.env.BASE_URL + "success");
							else
								alert("L'envoi du mail a échoué, veuillez réessayer");
						});
					}
					else
						alert("Veuillez valider le reCAPTCHA");
				});
			}
		}
	}
}

</script>

<style>
@import '@/assets/dropzone.css';

.dropzone {
	border: 1px dashed gray;
}

.info {
	width: 100%;
	align-items: center;
}
</style>
