import axios from 'axios'

class FormService {
	async upload(data) {
		return await axios.post(process.env.BASE_URL + "api/upload/to/fastupload", data)
	}

	async process(data) {
		return await axios.post(process.env.BASE_URL + "api/upload", data)
	}

	async captcha(data) {
		return await axios.post(process.env.BASE_URL + "api/captcha", data)
	}
}

export default new FormService;
